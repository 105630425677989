// footer colour
$footer:         darken(#979797, 6.5%) !default; 

.page-footer {
  padding: 20px;
  margin-top: 50px;
  color: darken($footer, 50);
  background-color: lighten($footer, 45);
  border-top: 1px solid lighten($footer, 30);

  .copyright {
    font-size: 11px;
  }
}

.mainbg {
  position: relative;
  top: -20px;
  width: 100%;
  height: 450px;
  background: url("/assets/images/kualalumpur.jpg");
  padding: 75px;
}

.img-responsive {
    margin: 0 auto;
}

.col-desc {
  font-weight: bold;
  margin-top: 0.2em;
  margin-bottom: 0.5em;
  line-height: 1.2125em; 
  font-size: 1.125em;
}

.landing {
  padding-top: 30px;
}

.white {
  color: #FFFFFF;
}

// Show studio logo
.img-pad {
  padding: 40px;
}

//socials modal border
.modal-outline {
  line-height: 30px;
}